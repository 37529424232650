$shadow_inner_highlight: inset 0px 0px 0px 1.5px rgba(255, 255, 255, 0.15);
// $shadow_inner: inset 0px 0px 0.5px 0px rgba(0, 0, 0, 0.65);
$shadow_inner: 0 0 0 black;
$shadow_small_outer: 0px 0px 3px rgba(0, 0, 0, 0.44);

.window {
  border-radius: 6px;
  // border: 0.5px solid rgba(0, 0, 0, 0.65);
  background-color: white;
  overflow: hidden;
  box-shadow: $shadow_inner_highlight, $shadow_inner,
    0px 6px 16px rgba(0, 0, 0, 0.5), $shadow_small_outer;

  &:focus,
  .active {
    box-shadow: $shadow_inner_highlight, $shadow_inner,
      0px 22px 45px rgba(0, 0, 0, 0.5), $shadow_small_outer;
  }
}
