.notifications {
  flex-grow: 1;

  display: flex;
  flex-direction: column-reverse;

  > * {
    margin-top: 15px;
  }
}
