.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  background-image: url('https://www.wallpaperback.net/wp-content/uploads/2018/08/Wallpaper%20Apple,%20iOS%2010,%204k,%205k,%20live%20wallpaper,%20live%20photo,%20wave,%20macOS%20Sierra,%20Abstract%206556315603.jpg');
  background-size: cover;
}
