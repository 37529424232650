.face-cam {
  position: relative;
  width: 400px;
  background-color: black;

  > video {
    display: block;
    width: 100%;
  }
}
