.status {
  height: 64px;
  justify-content: space-between;
}

.info {
  margin-left: 8px;
  flex-grow: 2;
  max-width: 265px;

  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.song-name {
  font-weight: 500;
}

.album-art {
  border-radius: 7px;
  width: 64px;
  height: 64px;
}

.play-status {
  font-size: 2rem;
  align-self: center;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 5px;
}
